@import 'common/variables';

@import 'bootstrap/bootstrap';
@import 'bootstrap/helpers';
@import 'bootstrap/bootstrap-utilities';

@import 'components/classes';
@import 'components/button';
@import 'components/layouts';
@import 'components/modal';
@import 'components/toast';

/* Webfont: ProximaNova-Regular */
@font-face {
  font-family: 'ProximaNovaRg';
  src: url('../../fonts/ProximaNovaRg/ProximaNova-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/ProximaNovaRg/ProximaNova-Regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/ProximaNovaRg/ProximaNova-Regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../../fonts/ProximaNovaRg/ProximaNova-Regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../fonts/ProximaNovaRg/ProximaNova-Regular.svg#ProximaNova-Regular')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
/* Webfont: ProximaNova-Bold */
@font-face {
  font-family: 'ProximaNovaRg';
  src: url('../../fonts/ProximaNovaRg/ProximaNova-Bold.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/ProximaNovaRg/ProximaNova-Bold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/ProximaNovaRg/ProximaNova-Bold.woff')
      format('woff'),
    /* Modern Browsers */ url('../../fonts/ProximaNovaRg/ProximaNova-Bold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../../fonts/ProximaNovaRg/ProximaNova-Bold.svg#ProximaNova-Bold')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
/* Webfont: TitanOne */
@font-face {
  font-family: 'TitanOne';
  src: url('../../fonts/TitanOne/TitanOne.eot'); /* IE9 Compat Modes */
  src: url('../../fonts/TitanOne/TitanOne.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../../fonts/TitanOne/TitanOne.woff') format('woff'),
    /* Modern Browsers */ url('../../fonts/TitanOne/TitanOne.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../../fonts/TitanOne/TitanOne.svg#TitanOne')
      format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
