.modal.fade.in .modal-body {
  bottom: 0;
  opacity: 1;
}
.modal-body {
  position: absolute;
  bottom: -250px;
  right: 5%;
  padding: 30px 15px 15px;
  width: 275px;
  height: 250px;
  background-color: #e5e5e5;
  border-radius: 6px 6px 0 0;
  opacity: 0;
  -webkit-transition-delay: 500ms;
  -moz-transition-delay: 500ms;
  -o-transition-delay: 500ms;
  transition-delay: 500ms;
  -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
}
.close {
  margin-top: -20px;
  text-shadow: 0 1px 0 #ffffff;
}
.popup-button {
  margin-left: 140px;
  margin-top: 77px;
  font-weight: bold;
}
