.Toastify__toast-container {
  padding: 10px 10px 0;
}

.Toastify__toast {
  margin-bottom: 0;
  border-radius: 2px;
  padding-right: 16px;
  padding-left: 16px;

  .Toastify__close-button {
    align-self: center;
  }
}
