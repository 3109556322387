.invalid-pin {
  .the__pin__container {
    .ui.input {
      input {
        box-shadow: inset 0px 1px 10px rgba(174, 1, 1, 1);
        border-color: rgba(174, 1, 1, 0.7);
      }
    }
  }
}
.the__pin__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .ui.input {
    flex: 0 0 20%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 0.5rem 0.25rem;
    box-sizing: border-box;

    &::before {
      content: '';
      display: table;
      padding-top: 100%;
    }

    input {
      flex-grow: 1;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0.625em;
      padding-right: 0.625em;
    }
  }
}
.ui.button {
  color: #fff;
  position: relative;
  height: 50px;
  width: 280px;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  overflow: hidden;
  z-index: 0;

  .fill {
    position: absolute;
    background-image: linear-gradient(
      180deg,
      rgba(186, 186, 186, 1) 0%,
      rgba(131, 131, 131, 1) 100%
    );
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
    z-index: -1;
    transition: all 0.4s ease;
    cursor: pointer;
  }

  &.primary {
    background: rgb(230, 15, 100);

    .fill {
      background: linear-gradient(
        180deg,
        rgba(230, 15, 100, 1) 0%,
        rgba(186, 7, 78, 1) 100%
      );
    }
  }
}

.__nav {
  display: flex;
  flex-flow: row wrap;
  width: 85%;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 360px) {
    margin-bottom: 0 0 25px;
  }

  .nav-item {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    position: relative;
    background-color: #fff;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      height: 80%;
      border-radius: 50%;
      z-index: 0;
      transition: all 0.05s linear;
    }

    &:hover::after {
      box-shadow: none !important;
    }

    svg {
      position: absolute;
      fill: #fff;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 36px;
      height: 36px;
    }
  }

  li:nth-child(1) .nav-item {
    background-color: #84ff00;

    &::after {
      background: rgb(54, 173, 90);
      background: linear-gradient(
        135deg,
        rgba(54, 173, 90, 0.6) 0%,
        rgba(54, 173, 90, 0.8) 90%,
        rgba(2, 85, 3, 1) 100%
      );
      box-shadow: 4px 8px 10px rgba(0, 0, 0, 0.7),
        2px 3px 4px rgba(0, 137, 3, 0.3), inset -1px -3px 4px rgba(4, 98, 5, 1),
        inset 3px 2px 2px rgba(5, 223, 7, 0.3);
    }
  }
  li:nth-child(2) .nav-item {
    background-color: #ff17fc;

    svg {
      width: 32px;
      height: 32px;
    }

    &::after {
      // background-color: #a105ab;
      background: rgb(190, 8, 201);
      background: linear-gradient(
        135deg,
        rgba(161, 5, 171, 0.6) 0%,
        rgba(161, 5, 171, 0.8) 90%,
        rgba(118, 3, 123, 1) 90%
      );
      box-shadow: 7px 8px 10px rgba(0, 0, 0, 0.7),
        0 -1px 4px rgba(255, 67, 250, 1),
        inset -2px -1px 6px rgba(118, 3, 123, 1),
        inset 2px 2px 2px rgba(235, 67, 246, 0.5);
    }
  }
  li:nth-child(3) .nav-item {
    background-color: #17bbff;

    &::after {
      background: rgb(190, 8, 201);
      background: rgb(51, 84, 179);
      background: linear-gradient(
        135deg,
        rgba(51, 84, 179, 1) 0%,
        rgba(37, 71, 168, 1) 100%
      );
      box-shadow: -5px 10px 10px rgba(0, 0, 0, 0.7),
        0px -1px 4px rgba(63, 108, 236, 1),
        inset 2px -4px 6px rgba(7, 33, 105, 0.8),
        inset 2px 2px 12px rgba(63, 108, 236, 0.5);
    }
  }
}

.ui.button.noButton {
  color: $highlight;
  font-size: 18px;
  height: 25px;
  width: auto;
  padding: 5px;
  text-transform: capitalize;
  background-color: transparent;

  &:active,
  &.active:active {
    background-color: transparent;
  }
}
