:root {
  --blue: #004fd4;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #d63384;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #004fd4;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --font-family-sans-serif: ProximaNovaRg, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: ProximaNovaRg, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1 {
  font-size: 2.5rem; }

h2 {
  font-size: 2rem; }

h3 {
  font-size: 1.75rem; }

h4 {
  font-size: 1.5rem; }

h5 {
  font-size: 1.25rem; }

h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0.5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 0.875em; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  color: #004fd4;
  text-decoration: none; }
  a:hover {
    color: #003288;
    text-decoration: underline; }

a:not([href]), a:not([href]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: textfield; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

progress {
  vertical-align: baseline; }

::-webkit-datetime-edit {
  overflow: visible;
  line-height: 0; }

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

main {
  display: block; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-s, .container-xsm, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-s, .container-xsm, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-s, .container-xsm, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-s, .container-xsm, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-s-1, .col-s-2, .col-s-3, .col-s-4, .col-s-5, .col-s-6, .col-s-7, .col-s-8, .col-s-9, .col-s-10, .col-s-11, .col-s-12, .col-s,
.col-s-auto, .col-xsm-1, .col-xsm-2, .col-xsm-3, .col-xsm-4, .col-xsm-5, .col-xsm-6, .col-xsm-7, .col-xsm-8, .col-xsm-9, .col-xsm-10, .col-xsm-11, .col-xsm-12, .col-xsm,
.col-xsm-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 5px;
  padding-left: 5px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 360px) {
  .col-s {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-s-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-s-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-s-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-s-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-s-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-s-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-s-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-s-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-s-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-s-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-s-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-s-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-s-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-s-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-s-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-s-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-s-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-s-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-s-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-s-0 {
    margin-left: 0; }
  .offset-s-1 {
    margin-left: 8.33333%; }
  .offset-s-2 {
    margin-left: 16.66667%; }
  .offset-s-3 {
    margin-left: 25%; }
  .offset-s-4 {
    margin-left: 33.33333%; }
  .offset-s-5 {
    margin-left: 41.66667%; }
  .offset-s-6 {
    margin-left: 50%; }
  .offset-s-7 {
    margin-left: 58.33333%; }
  .offset-s-8 {
    margin-left: 66.66667%; }
  .offset-s-9 {
    margin-left: 75%; }
  .offset-s-10 {
    margin-left: 83.33333%; }
  .offset-s-11 {
    margin-left: 91.66667%; } }

@media (min-width: 361px) {
  .col-xsm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xsm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xsm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xsm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xsm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xsm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xsm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xsm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xsm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xsm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xsm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xsm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xsm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xsm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xsm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xsm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xsm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xsm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xsm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xsm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xsm-0 {
    margin-left: 0; }
  .offset-xsm-1 {
    margin-left: 8.33333%; }
  .offset-xsm-2 {
    margin-left: 16.66667%; }
  .offset-xsm-3 {
    margin-left: 25%; }
  .offset-xsm-4 {
    margin-left: 33.33333%; }
  .offset-xsm-5 {
    margin-left: 41.66667%; }
  .offset-xsm-6 {
    margin-left: 50%; }
  .offset-xsm-7 {
    margin-left: 58.33333%; }
  .offset-xsm-8 {
    margin-left: 66.66667%; }
  .offset-xsm-9 {
    margin-left: 75%; }
  .offset-xsm-10 {
    margin-left: 83.33333%; }
  .offset-xsm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ''; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/*!
 * Bootstrap Utilities v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-0 {
  margin-right: 0 !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-0 {
  margin-left: 0 !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

.ml-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important; }

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important; }

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important; }

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important; }

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important; }

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important; }

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important; }

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important; }

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important; }

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important; }

.mt-n1 {
  margin-top: -0.25rem !important; }

.mt-n2 {
  margin-top: -0.5rem !important; }

.mt-n3 {
  margin-top: -1rem !important; }

.mt-n4 {
  margin-top: -1.5rem !important; }

.mt-n5 {
  margin-top: -3rem !important; }

.mr-n1 {
  margin-right: -0.25rem !important; }

.mr-n2 {
  margin-right: -0.5rem !important; }

.mr-n3 {
  margin-right: -1rem !important; }

.mr-n4 {
  margin-right: -1.5rem !important; }

.mr-n5 {
  margin-right: -3rem !important; }

.mb-n1 {
  margin-bottom: -0.25rem !important; }

.mb-n2 {
  margin-bottom: -0.5rem !important; }

.mb-n3 {
  margin-bottom: -1rem !important; }

.mb-n4 {
  margin-bottom: -1.5rem !important; }

.mb-n5 {
  margin-bottom: -3rem !important; }

.ml-n1 {
  margin-left: -0.25rem !important; }

.ml-n2 {
  margin-left: -0.5rem !important; }

.ml-n3 {
  margin-left: -1rem !important; }

.ml-n4 {
  margin-left: -1.5rem !important; }

.ml-n5 {
  margin-left: -3rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pr-0 {
  padding-right: 0 !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pr-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pl-0 {
  padding-left: 0 !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.pl-5 {
  padding-left: 3rem !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-justify {
  text-align: justify !important; }

.text-primary {
  color: #004fd4 !important; }

.text-secondary {
  color: #6c757d !important; }

.text-success {
  color: #28a745 !important; }

.text-info {
  color: #17a2b8 !important; }

.text-warning {
  color: #ffc107 !important; }

.text-danger {
  color: #dc3545 !important; }

.text-light {
  color: #f8f9fa !important; }

.text-dark {
  color: #343a40 !important; }

.text-white {
  color: #fff !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  color: inherit !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.bg-primary {
  background-color: #004fd4 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

.bg-dark {
  background-color: #343a40 !important; }

.bg-body {
  background-color: #fff !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-decoration-none {
  text-decoration: none !important; }

.font-italic {
  font-style: italic !important; }

.text-break {
  overflow-wrap: break-word !important;
  word-break: break-word !important; }

.font-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 360px) {
  .float-s-left {
    float: left !important; }
  .float-s-right {
    float: right !important; }
  .float-s-none {
    float: none !important; }
  .d-s-none {
    display: none !important; }
  .d-s-inline {
    display: inline !important; }
  .d-s-inline-block {
    display: inline-block !important; }
  .d-s-block {
    display: block !important; }
  .d-s-table {
    display: table !important; }
  .d-s-table-row {
    display: table-row !important; }
  .d-s-table-cell {
    display: table-cell !important; }
  .d-s-flex {
    display: flex !important; }
  .d-s-inline-flex {
    display: inline-flex !important; }
  .flex-s-fill {
    flex: 1 1 auto !important; }
  .flex-s-row {
    flex-direction: row !important; }
  .flex-s-column {
    flex-direction: column !important; }
  .flex-s-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-s-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-s-grow-0 {
    flex-grow: 0 !important; }
  .flex-s-grow-1 {
    flex-grow: 1 !important; }
  .flex-s-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-s-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-s-wrap {
    flex-wrap: wrap !important; }
  .flex-s-nowrap {
    flex-wrap: nowrap !important; }
  .flex-s-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-s-start {
    justify-content: flex-start !important; }
  .justify-content-s-end {
    justify-content: flex-end !important; }
  .justify-content-s-center {
    justify-content: center !important; }
  .justify-content-s-between {
    justify-content: space-between !important; }
  .justify-content-s-around {
    justify-content: space-around !important; }
  .align-items-s-start {
    align-items: flex-start !important; }
  .align-items-s-end {
    align-items: flex-end !important; }
  .align-items-s-center {
    align-items: center !important; }
  .align-items-s-baseline {
    align-items: baseline !important; }
  .align-items-s-stretch {
    align-items: stretch !important; }
  .align-content-s-start {
    align-content: flex-start !important; }
  .align-content-s-end {
    align-content: flex-end !important; }
  .align-content-s-center {
    align-content: center !important; }
  .align-content-s-between {
    align-content: space-between !important; }
  .align-content-s-around {
    align-content: space-around !important; }
  .align-content-s-stretch {
    align-content: stretch !important; }
  .align-self-s-auto {
    align-self: auto !important; }
  .align-self-s-start {
    align-self: flex-start !important; }
  .align-self-s-end {
    align-self: flex-end !important; }
  .align-self-s-center {
    align-self: center !important; }
  .align-self-s-baseline {
    align-self: baseline !important; }
  .align-self-s-stretch {
    align-self: stretch !important; }
  .order-s-first {
    order: -1 !important; }
  .order-s-0 {
    order: 0 !important; }
  .order-s-1 {
    order: 1 !important; }
  .order-s-2 {
    order: 2 !important; }
  .order-s-3 {
    order: 3 !important; }
  .order-s-4 {
    order: 4 !important; }
  .order-s-5 {
    order: 5 !important; }
  .order-s-last {
    order: 6 !important; }
  .m-s-0 {
    margin: 0 !important; }
  .m-s-1 {
    margin: 0.25rem !important; }
  .m-s-2 {
    margin: 0.5rem !important; }
  .m-s-3 {
    margin: 1rem !important; }
  .m-s-4 {
    margin: 1.5rem !important; }
  .m-s-5 {
    margin: 3rem !important; }
  .m-s-auto {
    margin: auto !important; }
  .mx-s-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-s-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-s-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-s-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-s-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-s-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-s-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-s-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-s-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-s-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-s-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-s-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-s-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-s-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-s-0 {
    margin-top: 0 !important; }
  .mt-s-1 {
    margin-top: 0.25rem !important; }
  .mt-s-2 {
    margin-top: 0.5rem !important; }
  .mt-s-3 {
    margin-top: 1rem !important; }
  .mt-s-4 {
    margin-top: 1.5rem !important; }
  .mt-s-5 {
    margin-top: 3rem !important; }
  .mt-s-auto {
    margin-top: auto !important; }
  .mr-s-0 {
    margin-right: 0 !important; }
  .mr-s-1 {
    margin-right: 0.25rem !important; }
  .mr-s-2 {
    margin-right: 0.5rem !important; }
  .mr-s-3 {
    margin-right: 1rem !important; }
  .mr-s-4 {
    margin-right: 1.5rem !important; }
  .mr-s-5 {
    margin-right: 3rem !important; }
  .mr-s-auto {
    margin-right: auto !important; }
  .mb-s-0 {
    margin-bottom: 0 !important; }
  .mb-s-1 {
    margin-bottom: 0.25rem !important; }
  .mb-s-2 {
    margin-bottom: 0.5rem !important; }
  .mb-s-3 {
    margin-bottom: 1rem !important; }
  .mb-s-4 {
    margin-bottom: 1.5rem !important; }
  .mb-s-5 {
    margin-bottom: 3rem !important; }
  .mb-s-auto {
    margin-bottom: auto !important; }
  .ml-s-0 {
    margin-left: 0 !important; }
  .ml-s-1 {
    margin-left: 0.25rem !important; }
  .ml-s-2 {
    margin-left: 0.5rem !important; }
  .ml-s-3 {
    margin-left: 1rem !important; }
  .ml-s-4 {
    margin-left: 1.5rem !important; }
  .ml-s-5 {
    margin-left: 3rem !important; }
  .ml-s-auto {
    margin-left: auto !important; }
  .m-s-n1 {
    margin: -0.25rem !important; }
  .m-s-n2 {
    margin: -0.5rem !important; }
  .m-s-n3 {
    margin: -1rem !important; }
  .m-s-n4 {
    margin: -1.5rem !important; }
  .m-s-n5 {
    margin: -3rem !important; }
  .mx-s-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-s-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-s-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-s-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-s-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-s-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-s-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-s-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-s-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-s-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-s-n1 {
    margin-top: -0.25rem !important; }
  .mt-s-n2 {
    margin-top: -0.5rem !important; }
  .mt-s-n3 {
    margin-top: -1rem !important; }
  .mt-s-n4 {
    margin-top: -1.5rem !important; }
  .mt-s-n5 {
    margin-top: -3rem !important; }
  .mr-s-n1 {
    margin-right: -0.25rem !important; }
  .mr-s-n2 {
    margin-right: -0.5rem !important; }
  .mr-s-n3 {
    margin-right: -1rem !important; }
  .mr-s-n4 {
    margin-right: -1.5rem !important; }
  .mr-s-n5 {
    margin-right: -3rem !important; }
  .mb-s-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-s-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-s-n3 {
    margin-bottom: -1rem !important; }
  .mb-s-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-s-n5 {
    margin-bottom: -3rem !important; }
  .ml-s-n1 {
    margin-left: -0.25rem !important; }
  .ml-s-n2 {
    margin-left: -0.5rem !important; }
  .ml-s-n3 {
    margin-left: -1rem !important; }
  .ml-s-n4 {
    margin-left: -1.5rem !important; }
  .ml-s-n5 {
    margin-left: -3rem !important; }
  .p-s-0 {
    padding: 0 !important; }
  .p-s-1 {
    padding: 0.25rem !important; }
  .p-s-2 {
    padding: 0.5rem !important; }
  .p-s-3 {
    padding: 1rem !important; }
  .p-s-4 {
    padding: 1.5rem !important; }
  .p-s-5 {
    padding: 3rem !important; }
  .px-s-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-s-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-s-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-s-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-s-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-s-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-s-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-s-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-s-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-s-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-s-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-s-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-s-0 {
    padding-top: 0 !important; }
  .pt-s-1 {
    padding-top: 0.25rem !important; }
  .pt-s-2 {
    padding-top: 0.5rem !important; }
  .pt-s-3 {
    padding-top: 1rem !important; }
  .pt-s-4 {
    padding-top: 1.5rem !important; }
  .pt-s-5 {
    padding-top: 3rem !important; }
  .pr-s-0 {
    padding-right: 0 !important; }
  .pr-s-1 {
    padding-right: 0.25rem !important; }
  .pr-s-2 {
    padding-right: 0.5rem !important; }
  .pr-s-3 {
    padding-right: 1rem !important; }
  .pr-s-4 {
    padding-right: 1.5rem !important; }
  .pr-s-5 {
    padding-right: 3rem !important; }
  .pb-s-0 {
    padding-bottom: 0 !important; }
  .pb-s-1 {
    padding-bottom: 0.25rem !important; }
  .pb-s-2 {
    padding-bottom: 0.5rem !important; }
  .pb-s-3 {
    padding-bottom: 1rem !important; }
  .pb-s-4 {
    padding-bottom: 1.5rem !important; }
  .pb-s-5 {
    padding-bottom: 3rem !important; }
  .pl-s-0 {
    padding-left: 0 !important; }
  .pl-s-1 {
    padding-left: 0.25rem !important; }
  .pl-s-2 {
    padding-left: 0.5rem !important; }
  .pl-s-3 {
    padding-left: 1rem !important; }
  .pl-s-4 {
    padding-left: 1.5rem !important; }
  .pl-s-5 {
    padding-left: 3rem !important; }
  .text-s-left {
    text-align: left !important; }
  .text-s-right {
    text-align: right !important; }
  .text-s-center {
    text-align: center !important; }
  .text-s-justify {
    text-align: justify !important; } }

@media (min-width: 361px) {
  .float-xsm-left {
    float: left !important; }
  .float-xsm-right {
    float: right !important; }
  .float-xsm-none {
    float: none !important; }
  .d-xsm-none {
    display: none !important; }
  .d-xsm-inline {
    display: inline !important; }
  .d-xsm-inline-block {
    display: inline-block !important; }
  .d-xsm-block {
    display: block !important; }
  .d-xsm-table {
    display: table !important; }
  .d-xsm-table-row {
    display: table-row !important; }
  .d-xsm-table-cell {
    display: table-cell !important; }
  .d-xsm-flex {
    display: flex !important; }
  .d-xsm-inline-flex {
    display: inline-flex !important; }
  .flex-xsm-fill {
    flex: 1 1 auto !important; }
  .flex-xsm-row {
    flex-direction: row !important; }
  .flex-xsm-column {
    flex-direction: column !important; }
  .flex-xsm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xsm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xsm-grow-0 {
    flex-grow: 0 !important; }
  .flex-xsm-grow-1 {
    flex-grow: 1 !important; }
  .flex-xsm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xsm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xsm-wrap {
    flex-wrap: wrap !important; }
  .flex-xsm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xsm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xsm-start {
    justify-content: flex-start !important; }
  .justify-content-xsm-end {
    justify-content: flex-end !important; }
  .justify-content-xsm-center {
    justify-content: center !important; }
  .justify-content-xsm-between {
    justify-content: space-between !important; }
  .justify-content-xsm-around {
    justify-content: space-around !important; }
  .align-items-xsm-start {
    align-items: flex-start !important; }
  .align-items-xsm-end {
    align-items: flex-end !important; }
  .align-items-xsm-center {
    align-items: center !important; }
  .align-items-xsm-baseline {
    align-items: baseline !important; }
  .align-items-xsm-stretch {
    align-items: stretch !important; }
  .align-content-xsm-start {
    align-content: flex-start !important; }
  .align-content-xsm-end {
    align-content: flex-end !important; }
  .align-content-xsm-center {
    align-content: center !important; }
  .align-content-xsm-between {
    align-content: space-between !important; }
  .align-content-xsm-around {
    align-content: space-around !important; }
  .align-content-xsm-stretch {
    align-content: stretch !important; }
  .align-self-xsm-auto {
    align-self: auto !important; }
  .align-self-xsm-start {
    align-self: flex-start !important; }
  .align-self-xsm-end {
    align-self: flex-end !important; }
  .align-self-xsm-center {
    align-self: center !important; }
  .align-self-xsm-baseline {
    align-self: baseline !important; }
  .align-self-xsm-stretch {
    align-self: stretch !important; }
  .order-xsm-first {
    order: -1 !important; }
  .order-xsm-0 {
    order: 0 !important; }
  .order-xsm-1 {
    order: 1 !important; }
  .order-xsm-2 {
    order: 2 !important; }
  .order-xsm-3 {
    order: 3 !important; }
  .order-xsm-4 {
    order: 4 !important; }
  .order-xsm-5 {
    order: 5 !important; }
  .order-xsm-last {
    order: 6 !important; }
  .m-xsm-0 {
    margin: 0 !important; }
  .m-xsm-1 {
    margin: 0.25rem !important; }
  .m-xsm-2 {
    margin: 0.5rem !important; }
  .m-xsm-3 {
    margin: 1rem !important; }
  .m-xsm-4 {
    margin: 1.5rem !important; }
  .m-xsm-5 {
    margin: 3rem !important; }
  .m-xsm-auto {
    margin: auto !important; }
  .mx-xsm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xsm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xsm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xsm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xsm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xsm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xsm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xsm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xsm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xsm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xsm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xsm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xsm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xsm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xsm-0 {
    margin-top: 0 !important; }
  .mt-xsm-1 {
    margin-top: 0.25rem !important; }
  .mt-xsm-2 {
    margin-top: 0.5rem !important; }
  .mt-xsm-3 {
    margin-top: 1rem !important; }
  .mt-xsm-4 {
    margin-top: 1.5rem !important; }
  .mt-xsm-5 {
    margin-top: 3rem !important; }
  .mt-xsm-auto {
    margin-top: auto !important; }
  .mr-xsm-0 {
    margin-right: 0 !important; }
  .mr-xsm-1 {
    margin-right: 0.25rem !important; }
  .mr-xsm-2 {
    margin-right: 0.5rem !important; }
  .mr-xsm-3 {
    margin-right: 1rem !important; }
  .mr-xsm-4 {
    margin-right: 1.5rem !important; }
  .mr-xsm-5 {
    margin-right: 3rem !important; }
  .mr-xsm-auto {
    margin-right: auto !important; }
  .mb-xsm-0 {
    margin-bottom: 0 !important; }
  .mb-xsm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xsm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xsm-3 {
    margin-bottom: 1rem !important; }
  .mb-xsm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xsm-5 {
    margin-bottom: 3rem !important; }
  .mb-xsm-auto {
    margin-bottom: auto !important; }
  .ml-xsm-0 {
    margin-left: 0 !important; }
  .ml-xsm-1 {
    margin-left: 0.25rem !important; }
  .ml-xsm-2 {
    margin-left: 0.5rem !important; }
  .ml-xsm-3 {
    margin-left: 1rem !important; }
  .ml-xsm-4 {
    margin-left: 1.5rem !important; }
  .ml-xsm-5 {
    margin-left: 3rem !important; }
  .ml-xsm-auto {
    margin-left: auto !important; }
  .m-xsm-n1 {
    margin: -0.25rem !important; }
  .m-xsm-n2 {
    margin: -0.5rem !important; }
  .m-xsm-n3 {
    margin: -1rem !important; }
  .m-xsm-n4 {
    margin: -1.5rem !important; }
  .m-xsm-n5 {
    margin: -3rem !important; }
  .mx-xsm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xsm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xsm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xsm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xsm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-xsm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xsm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xsm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xsm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xsm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-xsm-n1 {
    margin-top: -0.25rem !important; }
  .mt-xsm-n2 {
    margin-top: -0.5rem !important; }
  .mt-xsm-n3 {
    margin-top: -1rem !important; }
  .mt-xsm-n4 {
    margin-top: -1.5rem !important; }
  .mt-xsm-n5 {
    margin-top: -3rem !important; }
  .mr-xsm-n1 {
    margin-right: -0.25rem !important; }
  .mr-xsm-n2 {
    margin-right: -0.5rem !important; }
  .mr-xsm-n3 {
    margin-right: -1rem !important; }
  .mr-xsm-n4 {
    margin-right: -1.5rem !important; }
  .mr-xsm-n5 {
    margin-right: -3rem !important; }
  .mb-xsm-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xsm-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xsm-n3 {
    margin-bottom: -1rem !important; }
  .mb-xsm-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xsm-n5 {
    margin-bottom: -3rem !important; }
  .ml-xsm-n1 {
    margin-left: -0.25rem !important; }
  .ml-xsm-n2 {
    margin-left: -0.5rem !important; }
  .ml-xsm-n3 {
    margin-left: -1rem !important; }
  .ml-xsm-n4 {
    margin-left: -1.5rem !important; }
  .ml-xsm-n5 {
    margin-left: -3rem !important; }
  .p-xsm-0 {
    padding: 0 !important; }
  .p-xsm-1 {
    padding: 0.25rem !important; }
  .p-xsm-2 {
    padding: 0.5rem !important; }
  .p-xsm-3 {
    padding: 1rem !important; }
  .p-xsm-4 {
    padding: 1.5rem !important; }
  .p-xsm-5 {
    padding: 3rem !important; }
  .px-xsm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xsm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xsm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xsm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xsm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xsm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xsm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xsm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xsm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xsm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xsm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xsm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xsm-0 {
    padding-top: 0 !important; }
  .pt-xsm-1 {
    padding-top: 0.25rem !important; }
  .pt-xsm-2 {
    padding-top: 0.5rem !important; }
  .pt-xsm-3 {
    padding-top: 1rem !important; }
  .pt-xsm-4 {
    padding-top: 1.5rem !important; }
  .pt-xsm-5 {
    padding-top: 3rem !important; }
  .pr-xsm-0 {
    padding-right: 0 !important; }
  .pr-xsm-1 {
    padding-right: 0.25rem !important; }
  .pr-xsm-2 {
    padding-right: 0.5rem !important; }
  .pr-xsm-3 {
    padding-right: 1rem !important; }
  .pr-xsm-4 {
    padding-right: 1.5rem !important; }
  .pr-xsm-5 {
    padding-right: 3rem !important; }
  .pb-xsm-0 {
    padding-bottom: 0 !important; }
  .pb-xsm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xsm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xsm-3 {
    padding-bottom: 1rem !important; }
  .pb-xsm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xsm-5 {
    padding-bottom: 3rem !important; }
  .pl-xsm-0 {
    padding-left: 0 !important; }
  .pl-xsm-1 {
    padding-left: 0.25rem !important; }
  .pl-xsm-2 {
    padding-left: 0.5rem !important; }
  .pl-xsm-3 {
    padding-left: 1rem !important; }
  .pl-xsm-4 {
    padding-left: 1.5rem !important; }
  .pl-xsm-5 {
    padding-left: 3rem !important; }
  .text-xsm-left {
    text-align: left !important; }
  .text-xsm-right {
    text-align: right !important; }
  .text-xsm-center {
    text-align: center !important; }
  .text-xsm-justify {
    text-align: justify !important; } }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-sm-n1 {
    margin-top: -0.25rem !important; }
  .mt-sm-n2 {
    margin-top: -0.5rem !important; }
  .mt-sm-n3 {
    margin-top: -1rem !important; }
  .mt-sm-n4 {
    margin-top: -1.5rem !important; }
  .mt-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n1 {
    margin-right: -0.25rem !important; }
  .mr-sm-n2 {
    margin-right: -0.5rem !important; }
  .mr-sm-n3 {
    margin-right: -1rem !important; }
  .mr-sm-n4 {
    margin-right: -1.5rem !important; }
  .mr-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-sm-n3 {
    margin-bottom: -1rem !important; }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n1 {
    margin-left: -0.25rem !important; }
  .ml-sm-n2 {
    margin-left: -0.5rem !important; }
  .ml-sm-n3 {
    margin-left: -1rem !important; }
  .ml-sm-n4 {
    margin-left: -1.5rem !important; }
  .ml-sm-n5 {
    margin-left: -3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; }
  .text-sm-justify {
    text-align: justify !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-md-n1 {
    margin-top: -0.25rem !important; }
  .mt-md-n2 {
    margin-top: -0.5rem !important; }
  .mt-md-n3 {
    margin-top: -1rem !important; }
  .mt-md-n4 {
    margin-top: -1.5rem !important; }
  .mt-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n1 {
    margin-right: -0.25rem !important; }
  .mr-md-n2 {
    margin-right: -0.5rem !important; }
  .mr-md-n3 {
    margin-right: -1rem !important; }
  .mr-md-n4 {
    margin-right: -1.5rem !important; }
  .mr-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-md-n3 {
    margin-bottom: -1rem !important; }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n1 {
    margin-left: -0.25rem !important; }
  .ml-md-n2 {
    margin-left: -0.5rem !important; }
  .ml-md-n3 {
    margin-left: -1rem !important; }
  .ml-md-n4 {
    margin-left: -1.5rem !important; }
  .ml-md-n5 {
    margin-left: -3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; }
  .text-md-justify {
    text-align: justify !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-lg-n1 {
    margin-top: -0.25rem !important; }
  .mt-lg-n2 {
    margin-top: -0.5rem !important; }
  .mt-lg-n3 {
    margin-top: -1rem !important; }
  .mt-lg-n4 {
    margin-top: -1.5rem !important; }
  .mt-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n1 {
    margin-right: -0.25rem !important; }
  .mr-lg-n2 {
    margin-right: -0.5rem !important; }
  .mr-lg-n3 {
    margin-right: -1rem !important; }
  .mr-lg-n4 {
    margin-right: -1.5rem !important; }
  .mr-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-lg-n3 {
    margin-bottom: -1rem !important; }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n1 {
    margin-left: -0.25rem !important; }
  .ml-lg-n2 {
    margin-left: -0.5rem !important; }
  .ml-lg-n3 {
    margin-left: -1rem !important; }
  .ml-lg-n4 {
    margin-left: -1.5rem !important; }
  .ml-lg-n5 {
    margin-left: -3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; }
  .text-lg-justify {
    text-align: justify !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mr-xl-1 {
    margin-right: 0.25rem !important; }
  .mr-xl-2 {
    margin-right: 0.5rem !important; }
  .mr-xl-3 {
    margin-right: 1rem !important; }
  .mr-xl-4 {
    margin-right: 1.5rem !important; }
  .mr-xl-5 {
    margin-right: 3rem !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .ml-xl-1 {
    margin-left: 0.25rem !important; }
  .ml-xl-2 {
    margin-left: 0.5rem !important; }
  .ml-xl-3 {
    margin-left: 1rem !important; }
  .ml-xl-4 {
    margin-left: 1.5rem !important; }
  .ml-xl-5 {
    margin-left: 3rem !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-xl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xl-n3 {
    margin-top: -1rem !important; }
  .mt-xl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n1 {
    margin-right: -0.25rem !important; }
  .mr-xl-n2 {
    margin-right: -0.5rem !important; }
  .mr-xl-n3 {
    margin-right: -1rem !important; }
  .mr-xl-n4 {
    margin-right: -1.5rem !important; }
  .mr-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n1 {
    margin-left: -0.25rem !important; }
  .ml-xl-n2 {
    margin-left: -0.5rem !important; }
  .ml-xl-n3 {
    margin-left: -1rem !important; }
  .ml-xl-n4 {
    margin-left: -1.5rem !important; }
  .ml-xl-n5 {
    margin-left: -3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pr-xl-1 {
    padding-right: 0.25rem !important; }
  .pr-xl-2 {
    padding-right: 0.5rem !important; }
  .pr-xl-3 {
    padding-right: 1rem !important; }
  .pr-xl-4 {
    padding-right: 1.5rem !important; }
  .pr-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .pl-xl-1 {
    padding-left: 0.25rem !important; }
  .pl-xl-2 {
    padding-left: 0.5rem !important; }
  .pl-xl-3 {
    padding-left: 1rem !important; }
  .pl-xl-4 {
    padding-left: 1.5rem !important; }
  .pl-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; }
  .text-xl-justify {
    text-align: justify !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.highlight {
  color: #ffce00; }

.invalid-pin .the__pin__container .ui.input input {
  box-shadow: inset 0px 1px 10px #ae0101;
  border-color: rgba(174, 1, 1, 0.7); }

.the__pin__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .the__pin__container .ui.input {
    flex: 0 0 20%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 0.5rem 0.25rem;
    box-sizing: border-box; }
    .the__pin__container .ui.input::before {
      content: '';
      display: table;
      padding-top: 100%; }
    .the__pin__container .ui.input input {
      flex-grow: 1;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 0.625em;
      padding-right: 0.625em; }

.ui.button {
  color: #fff;
  position: relative;
  height: 50px;
  width: 280px;
  border: 0;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  overflow: hidden;
  z-index: 0; }
  .ui.button .fill {
    position: absolute;
    background-image: linear-gradient(180deg, #bababa 0%, #838383 100%);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
    z-index: -1;
    transition: all 0.4s ease;
    cursor: pointer; }
  .ui.button.primary {
    background: #e60f64; }
    .ui.button.primary .fill {
      background: linear-gradient(180deg, #e60f64 0%, #ba074e 100%); }

.__nav {
  display: flex;
  flex-flow: row wrap;
  width: 85%;
  list-style: none;
  margin: 0;
  padding: 0; }
  @media (max-width: 360px) {
    .__nav {
      margin-bottom: 0 0 25px; } }
  .__nav .nav-item {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    position: relative;
    background-color: #fff; }
    .__nav .nav-item::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      height: 80%;
      border-radius: 50%;
      z-index: 0;
      transition: all 0.05s linear; }
    .__nav .nav-item:hover::after {
      box-shadow: none !important; }
    .__nav .nav-item svg {
      position: absolute;
      fill: #fff;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 36px;
      height: 36px; }
  .__nav li:nth-child(1) .nav-item {
    background-color: #84ff00; }
    .__nav li:nth-child(1) .nav-item::after {
      background: #36ad5a;
      background: linear-gradient(135deg, rgba(54, 173, 90, 0.6) 0%, rgba(54, 173, 90, 0.8) 90%, #025503 100%);
      box-shadow: 4px 8px 10px rgba(0, 0, 0, 0.7), 2px 3px 4px rgba(0, 137, 3, 0.3), inset -1px -3px 4px #046205, inset 3px 2px 2px rgba(5, 223, 7, 0.3); }
  .__nav li:nth-child(2) .nav-item {
    background-color: #ff17fc; }
    .__nav li:nth-child(2) .nav-item svg {
      width: 32px;
      height: 32px; }
    .__nav li:nth-child(2) .nav-item::after {
      background: #be08c9;
      background: linear-gradient(135deg, rgba(161, 5, 171, 0.6) 0%, rgba(161, 5, 171, 0.8) 90%, #76037b 90%);
      box-shadow: 7px 8px 10px rgba(0, 0, 0, 0.7), 0 -1px 4px #ff43fa, inset -2px -1px 6px #76037b, inset 2px 2px 2px rgba(235, 67, 246, 0.5); }
  .__nav li:nth-child(3) .nav-item {
    background-color: #17bbff; }
    .__nav li:nth-child(3) .nav-item::after {
      background: #be08c9;
      background: #3354b3;
      background: linear-gradient(135deg, #3354b3 0%, #2547a8 100%);
      box-shadow: -5px 10px 10px rgba(0, 0, 0, 0.7), 0px -1px 4px #3f6cec, inset 2px -4px 6px rgba(7, 33, 105, 0.8), inset 2px 2px 12px rgba(63, 108, 236, 0.5); }

.ui.button.noButton {
  color: #ffce00;
  font-size: 18px;
  height: 25px;
  width: auto;
  padding: 5px;
  text-transform: capitalize;
  background-color: transparent; }
  .ui.button.noButton:active, .ui.button.noButton.active:active {
    background-color: transparent; }

body {
  background: #004fd4;
  background: radial-gradient(circle, #004fd4 0%, #45006a 130%); }

html,
body {
  height: 100%; }

#root {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #004fd4;
  background: radial-gradient(circle, #004fd4 0%, #45006a 130%); }
  @media (min-width: 576px) {
    #root {
      background: transparent !important;
      position: relative;
      max-width: 411px;
      max-height: 736px;
      margin: 0 auto; } }

#page {
  padding: 25px 15px 0;
  height: 100%;
  position: relative;
  z-index: 1; }
  @media (min-width: 576px) {
    #page {
      overflow-x: scroll;
      max-width: 411px;
      max-height: 736px; } }

#ProfilePageAvatar {
  background-image: url("../../images/avatar.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

img.bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.12;
  /* Preserve aspect ratio */
  min-width: 100%;
  min-height: 100%; }
  @media (min-width: 576px) {
    img.bg {
      position: absolute;
      display: none; } }

p,
.p-typography {
  color: #fff;
  font-size: 18px; }

.onboard__container #join__element .inner {
  padding-left: 5px;
  padding-right: 5px; }

.onboard__container #join__element .ui.input {
  margin-bottom: 25px; }

.onboard__container #join__element #joinButton {
  display: none; }
  .onboard__container #join__element #joinButton.entered {
    display: block; }

.onboard__container #slider__element .image-wrapper {
  min-height: 300px; }

/** OTP **/
.resend {
  color: #ffc800;
  font-family: TitanOne, "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  display: inline-block;
  margin-left: 12px; }
  .resend.counting {
    color: #ced2d5; }
    @media (max-width: 360.98px) {
      .resend.counting {
        font-size: 16px; } }

button.ui.button.resend {
  color: #ffc800;
  background-color: transparent;
  display: inline-block;
  width: auto;
  padding: 0;
  height: 20px;
  margin-left: 12px;
  font-size: 18px; }
  button.ui.button.resend::after, button.ui.button.resend::before {
    width: 0.875em;
    height: 0.875em;
    margin-top: -0.35em;
    border-width: 0.14em; }

.page-control {
  margin-bottom: 20px; }

.player-meta .profile-summary {
  position: relative;
  padding-left: 30px; }
  .player-meta .profile-summary .blue__box {
    padding-left: 30px;
    padding-top: 5px; }
  .player-meta .profile-summary .image {
    border-radius: 50%;
    height: 52px;
    width: 52px;
    position: absolute;
    background: #004fd4;
    top: -5px;
    left: 0;
    background-size: contain; }
    .player-meta .profile-summary .image .circle__spin {
      position: absolute;
      left: -5px;
      top: -5px;
      width: 62px;
      height: 62px; }
    .player-meta .profile-summary .image .circle__spin svg {
      width: 100%;
      height: 100%; }
    .player-meta .profile-summary .image .circle__spin circle {
      stroke-width: 6;
      /* control the dashes width */
      stroke-dasharray: 100, 20;
      /* control the dashes spacing */
      fill: none;
      stroke: #fff; }
    .player-meta .profile-summary .image .circle__spin svg {
      animation: spin 10s linear infinite; }

@keyframes spin {
  100% {
    transform: rotateZ(360deg); } }
  .player-meta .profile-summary .rank,
  .player-meta .profile-summary points {
    display: block; }
  .player-meta .profile-summary .rank {
    font-weight: normal;
    position: relative;
    font-size: 9px;
    padding-bottom: 2px; }
    .player-meta .profile-summary .rank::after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 0;
      height: 1px;
      width: 75%;
      background: rgba(25, 255, 255, 0.2); }

.player-meta .riskIt .blue__box {
  position: relative;
  padding-left: 30px; }
  .player-meta .riskIt .blue__box .desc {
    position: absolute;
    bottom: -12px;
    right: 0; }
    .player-meta .riskIt .blue__box .desc .blue__box {
      height: auto;
      padding: 2px 5px 2px 15px; }

.player-meta .riskIt .keys {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end; }
  .player-meta .riskIt .keys .key-item {
    width: 20px;
    height: 20px;
    margin-right: 3px; }

.player-meta .blue__box {
  background-color: #004fd4;
  width: 100%;
  height: 20px;
  border-radius: 5px;
  padding: 1px 5px;
  height: 100%;
  color: #fff;
  height: 40px;
  font-size: 11px;
  font-weight: bold; }

.adv__box .object {
  height: 190px;
  width: 100%;
  overflow: hidden; }

.playButton .playButtonWrapper {
  width: 156px; }

.page-desc {
  text-align: center;
  margin: 0 auto;
  max-width: 184px;
  margin-bottom: 10px; }
  .page-desc p {
    font-size: 1rem; }

.game-select {
  margin-bottom: 10px;
  margin-left: -10px;
  margin-right: -10px; }
  .game-select .col-4 {
    flex: 0 0 37.4999999%;
    max-width: 37.4999999%; }
    @media (max-width: 360px) {
      .game-select .col-4 {
        flex: 0 0 39.4999999%;
        max-width: 39.4999999%; } }
  .game-select .game-item {
    color: #fff;
    background-color: rgba(5, 22, 87, 0.4);
    border-radius: 4px;
    padding: 30px 16px 15px;
    height: 100%;
    display: flex;
    flex-flow: column;
    box-shadow: inset 0px 8px 48px rgba(0, 0, 0, 0.1); }
    @media (max-width: 375px) {
      .game-select .game-item {
        padding-left: 15px;
        padding-right: 15px; } }
    .game-select .game-item.no-risk-it {
      opacity: 0.25; }
    .game-select .game-item h3 {
      margin-bottom: 8px;
      font-size: 1.125rem;
      font-weight: bold; }
    .game-select .game-item .object {
      max-width: 70px;
      margin-bottom: 20px;
      display: block; }
      .game-select .game-item .object .inner {
        height: 71px;
        position: relative; }
        .game-select .game-item .object .inner .media-object {
          position: absolute;
          top: 50%;
          transform: translateY(-50%); }
      .game-select .game-item .object.svg svg {
        max-width: 55px;
        height: auto; }
      .game-select .game-item .object.qod img {
        width: 41px;
        height: auto; }
    .game-select .game-item .content {
      font-size: 12px;
      line-height: 1.143rem;
      margin-bottom: 15px; }
    .game-select .game-item .extra {
      display: flex;
      height: 100%;
      align-items: flex-end; }
    .game-select .game-item .play {
      color: #ffce00;
      font-family: TitanOne, "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
      font-size: 0.875rem;
      position: relative; }
      .game-select .game-item .play:after {
        content: '';
        position: absolute;
        right: -13px;
        bottom: 4px;
        width: 5.8px;
        height: 9.42px;
        background-image: url(../../images/back-button.svg);
        background-repeat: no-repeat;
        background-size: contain;
        transform: rotate(180deg); }
  .game-select .game-item.adv {
    padding: 0;
    overflow: hidden;
    position: relative; }
    .game-select .game-item.adv .extra {
      position: absolute;
      bottom: 15px;
      left: 16px; }

.page-control-points {
  display: flex; }
  .page-control-points.right {
    justify-content: flex-end; }
  .page-control-points .points-wrapper {
    display: inline-block;
    padding-left: 10px;
    overflow: hidden;
    line-height: 1; }
    .page-control-points .points-wrapper .inner {
      background-color: #fff;
      width: 100%;
      padding: 0 6px 0 20px;
      height: 25px;
      border: 3px solid #004fd4;
      border-radius: 13px;
      display: flex;
      align-items: center;
      position: relative; }
      .page-control-points .points-wrapper .inner::before {
        content: '';
        position: absolute;
        left: 0;
        width: 5px;
        height: 100%;
        background-color: #004fd4;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px; }
    .page-control-points .points-wrapper .points {
      font-size: 12px;
      font-weight: bold;
      margin-top: 2px; }
    .page-control-points .points-wrapper .icon {
      position: absolute;
      left: -4px;
      top: -1px; }

.AreYouReady .object img {
  margin: 0 auto; }

.AreYouReady p {
  font-size: 1.125rem;
  line-height: 28px; }

#Timer {
  width: 30px;
  height: 30px;
  position: relative; }
  #Timer svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: -4px;
    left: 0; }
  #Timer svg#circle {
    transform: rotateY(-180deg) rotateZ(-90deg); }
    #Timer svg#circle circle {
      stroke-dasharray: 500px;
      stroke-dashoffset: 0px;
      stroke-linecap: round;
      stroke-width: 14px;
      stroke: #00a0f5;
      fill: none;
      animation: countdown linear forwards; }
  #Timer #secondHand {
    transform-origin: 50% 50%;
    animation: animate-seconds linear; }

@keyframes animate-seconds {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes countdown {
  from {
    stroke-dashoffset: 0px; }
  to {
    stroke-dashoffset: 500px; } }

.ui.dimmer.popeye {
  position: fixed;
  padding-top: 25px;
  background-color: #004fd4 !important;
  background: radial-gradient(circle, #004fd4 0%, #45006a 100%) !important;
  background-image: url(../../images/bg-deep.png) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important; }
  .ui.dimmer.popeye.cover {
    background-image: none !important;
    background: linear-gradient(-45deg, #00317c, #45006a, #004fd4, #0048b8) !important;
    background-size: 350% 350% !important;
    animation: gradientBG 5s ease infinite !important; }
    .ui.dimmer.popeye.cover svg {
      width: 180px; }

@keyframes gradientBG {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

.blinking-cursor {
  position: absolute;
  width: 1px;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  height: 16px; }
  .blinking-cursor::after {
    width: 1px;
    height: 16px;
    background-color: #fff;
    animation: 1s blink step-end infinite;
    content: '';
    display: block; }

@keyframes blink {
  from,
  to {
    opacity: 0; }
  50% {
    opacity: 1; } }

/* style.css*/
/* ----------------------------------------------
 * Generated by Animista on 2019-12-1 18:23:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
@keyframes slideIn {
  0% {
    transform: translateY(-1000px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

.slideIn {
  animation-name: slideIn; }

@keyframes slideOut {
  0% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(-1000px);
    opacity: 0; } }

.slideOut {
  animation-name: slideOut; }

/* Not needed with the cssTransition helper */
.animate {
  animation-duration: 800ms; }

.campaignCountDownWrapper {
  position: absolute;
  bottom: 0; }

#campaignCountDown {
  display: flex;
  justify-content: space-between;
  width: 165px; }
  #campaignCountDown > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-weight: 600;
    font-size: 18px; }
    #campaignCountDown > div > span {
      font-size: 10px; }

/**
 * Error Boundary 
 */
#errorBoundary .img {
  width: 320px;
  height: 190px;
  background-image: url(../../images/ErrorBoundaryImage.png);
  background-repeat: no-repeat;
  background-size: contain; }

#errorBoundary .content {
  width: 80%;
  margin-top: 25px; }
  #errorBoundary .content h1 {
    color: #fff;
    font-size: 20px;
    font-weight: 900; }
  #errorBoundary .content p {
    font-size: 1rem; }
  #errorBoundary .content ul li {
    color: #fff; }

.modal.fade.in .modal-body {
  bottom: 0;
  opacity: 1; }

.modal-body {
  position: absolute;
  bottom: -250px;
  right: 5%;
  padding: 30px 15px 15px;
  width: 275px;
  height: 250px;
  background-color: #e5e5e5;
  border-radius: 6px 6px 0 0;
  opacity: 0;
  -webkit-transition-delay: 500ms;
  -moz-transition-delay: 500ms;
  -o-transition-delay: 500ms;
  transition-delay: 500ms;
  -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out;
  transition: opacity 0.3s ease-out, bottom 0.3s ease-out; }

.close {
  margin-top: -20px;
  text-shadow: 0 1px 0 #ffffff; }

.popup-button {
  margin-left: 140px;
  margin-top: 77px;
  font-weight: bold; }

.Toastify__toast-container {
  padding: 10px 10px 0; }

.Toastify__toast {
  margin-bottom: 0;
  border-radius: 2px;
  padding-right: 16px;
  padding-left: 16px; }
  .Toastify__toast .Toastify__close-button {
    align-self: center; }

/* Webfont: ProximaNova-Regular */
@font-face {
  font-family: 'ProximaNovaRg';
  src: url("../../fonts/ProximaNovaRg/ProximaNova-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../../fonts/ProximaNovaRg/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/ProximaNovaRg/ProximaNova-Regular.woff") format("woff"), url("../../fonts/ProximaNovaRg/ProximaNova-Regular.ttf") format("truetype"), url("../../fonts/ProximaNovaRg/ProximaNova-Regular.svg#ProximaNova-Regular") format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap; }

/* Webfont: ProximaNova-Bold */
@font-face {
  font-family: 'ProximaNovaRg';
  src: url("../../fonts/ProximaNovaRg/ProximaNova-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../../fonts/ProximaNovaRg/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/ProximaNovaRg/ProximaNova-Bold.woff") format("woff"), url("../../fonts/ProximaNovaRg/ProximaNova-Bold.ttf") format("truetype"), url("../../fonts/ProximaNovaRg/ProximaNova-Bold.svg#ProximaNova-Bold") format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-display: swap; }

/* Webfont: TitanOne */
@font-face {
  font-family: 'TitanOne';
  src: url("../../fonts/TitanOne/TitanOne.eot");
  /* IE9 Compat Modes */
  src: url("../../fonts/TitanOne/TitanOne.eot?#iefix") format("embedded-opentype"), url("../../fonts/TitanOne/TitanOne.woff") format("woff"), url("../../fonts/TitanOne/TitanOne.ttf") format("truetype"), url("../../fonts/TitanOne/TitanOne.svg#TitanOne") format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  font-display: swap; }
